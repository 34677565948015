import { inject, Injectable, isDevMode, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from "@angular/common";
import { StringUtils } from '@app/core/utils/string.utils';

@Injectable({
  providedIn: 'root',
})

export class CookieService {
  private readonly _domain = '.industrybuying.com';
  private readonly _platformId = inject(PLATFORM_ID);

  constructor() {}

  getCookie(cname: string): string {
    if (!isPlatformBrowser(this._platformId)) {
      console.warn("Cookies cannot be accessed on the server-side");
      return "";
    }

    const name: string = StringUtils.format("{0}=",cname);
    const ca: string[] = document.cookie.split(";");

    for (let i = 0; i < ca.length; i++) {
      const c = ca[i].trim();
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  setCookie(name: string, value: any, exDays: number = 1) {
    if (!isPlatformBrowser(this._platformId)) {
      console.warn("Cookies cannot be set on the server-side");
      return;
    }

    const d = new Date();
    d.setTime(d.getTime() + exDays * 24 * 60 * 60 * 1000);

    let cookie: string = StringUtils.format("{0}={1};expires={2};path=/;",name,value,d.toUTCString());
    if (!isDevMode()) {
      cookie += StringUtils.format("domain={0};", this._domain);
    }
    document.cookie = cookie;
  }

  clearCookie(name: string) {
    if (!isPlatformBrowser(this._platformId)) {
      console.warn("Cookies cannot be cleared on the server-side");
      return;
    }

    document.cookie = StringUtils.format("{0}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;", name);
  }

  private _getAllCookies(): { [key: string]: string } {
    const cookies: { [key: string]: string } = {};
    document.cookie.split('; ').forEach((cookie) => {
      const [name, value] = cookie.split('=');
      cookies[name] = decodeURIComponent(value);
    });
    return cookies;
  }

  getFilteredCookies(prefix: string): { [key: string]: string } {
    const allCookies = this._getAllCookies();
    return Object.keys(allCookies)
      .filter((key) => key.startsWith(prefix))
      .reduce((acc, key) => {
        acc[key] = allCookies[key];
        return acc;
      }, {} as { [key: string]: string });
  }
}
